import React from 'react';
import { Link } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Services = ({primary, items}) => {
  return (
    <>
      <div className="w-full pt-12 border-t-2 border-grey">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex justify-between">
              <h3 className="font-brand leading-none font-black text-4xl md:text-5xl text-blue mb-12">
                {primary.tag}
              </h3>
          </div>
        </div>
      </div>
      <div className="w-full pb-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-24">
          {items.map((entry,i) => {
            return (
              <div key={i}>
                {entry.page_link1.url !== null ? 
                  <Link to={linkResolver(entry.page_link1)}>
                    <div className="group" data-sal-duration="1000" data-sal="slide-up" data-sal-easing="ease">
                      <div className="pb-3 bg-blue">
                        <GatsbyImage className="aspect-w-5 aspect-h-3" style={{ width: "100%", height: "100%", display: "block", objectFit: 'cover' }} image={entry.image1.gatsbyImageData} alt={entry.image1.alt ? entry.image1.alt : ''} />
                      </div>
                      <div className="mt-6">
                        <h3 className="font-brand font-bold text-3xl text-blue border-b-4 border-white group-hover:border-blue inline-block">{entry.heading1}</h3>
                      </div>
                      <div className="mt-6">
                        <div className="font-sans prose prose-lg text-grey-6 leading-normal">
                          <PrismicRichText linkResolver={linkResolver} field={entry.text1.richText} />
                        </div>
                      </div>
                    </div>
                  </Link>
                  :
                  <a title={entry.heading1} rel="nofollow noopener" target="_blank" href={entry.web_link}>
                    <div key={i} className="group" data-sal-duration="1000" data-sal="slide-up" data-sal-easing="ease">
                      <div className="pb-3 bg-blue">
                        <GatsbyImage className="aspect-w-5 aspect-h-3" style={{ width: "100%", height: "100%", display: "block", objectFit: 'cover' }} image={entry.image1.gatsbyImageData} alt={entry.image1.alt ? entry.image1.alt : ''} />
                      </div>
                      <div className="mt-6">
                        <h3 className="font-brand font-bold text-3xl text-blue border-b-4 border-white group-hover:border-blue inline-block">{entry.heading1}</h3>
                      </div>
                      <div className="mt-6">
                        <div className="font-sans prose prose-lg text-grey-6 leading-normal">
                          <PrismicRichText linkResolver={linkResolver} field={entry.text1.richText} />
                        </div>
                      </div>
                    </div>
                  </a>
                }
              </div>
            )
          })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
