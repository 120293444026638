import * as React from "react"
import { graphql } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import Seo from '../components/seo'
import Footer from '../components/footer'
import Header from "../components/header"
import Services from "../components/services"
import Contact from "../components/contact"
import Heading from "../components/heading"
import Text from "../components/text"

const AboutPage = ({data}) => {
  const entry = data.prismicAboutPage
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image={entry.data.meta_image.url} pathname={linkResolver(entry.data)} />
      <Header logo='cuddon' />
      {entry.data.body.map((slice) => {
        if (slice.slice_type === 'contact') {
          return (
            <div key={slice.id}>
              <Contact primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'text') {
          return (
            <div key={slice.id}>
              <Text primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'heading') {
          return (
            <div key={slice.id}>
              <Heading primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'services') {
          return (
            <div key={slice.id}>
              <Services primary={slice.primary} items={slice.items} />
            </div>
          )
        }
      })}
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicAboutPage {
    data {
      meta_description
      page_title
      meta_image {
        url
      }
      body {
        ... on PrismicAboutPageDataBodyText {
          id
          primary {
            button1
            heading1
            page_link1 {
              url
              uid
              type
            }
            text1 {
              richText
            }
            tag
          }
          slice_type
        }
        ... on PrismicAboutPageDataBodyContact {
          id
          slice_type
          primary {
            heading1
            text1 {
              richText
            }
            background {
              gatsbyImageData
              alt
            }
          }
        }
        ... on PrismicAboutPageDataBodyHeading {
          id
          slice_type
          primary {
            breadcrumb
            button1
            heading1
            text1 {
              richText
            }
            page_link1 {
              url
              uid
              type
            }
            parent {
              url
              uid
              type
              link_type
            }
            image1 {
              alt
              gatsbyImageData
            }
          }
        }
        ... on PrismicAboutPageDataBodyServices {
          id
          items {
            button1
            heading1
            image1 {
              alt
              gatsbyImageData
            }
            page_link1 {
              uid
              url
              type
            }
            text1 {
              richText
            }
          }
          slice_type
          primary {
            tag
          }
        }
      }
    }
  }
}
`

export default AboutPage
